

<!-- footer Panel -->
<footer class="row footerSection pull_left" id="footerID">
<div class="wrap gapping1">

<div class="col-sm-1Third paddingInBoxExtra tabpaddingInBox mobDisplayNone">
<div class="row titleFont font20 brown marginBottom">About Insta Global Tourism </div>
 <div class="row alignJustify mobfont12">Insta Global Tourism is dedicated towards providing an inspiring and premium travel experience. Our services are driven by the vision of uniting smart technology with customer satisfaction. We facilitate best deals for our customers in booking hotels and tours across multiple destinations. <!--We stand by the three core values across all levels – best available options, transparent pricing, and premium experience. --></div>
<a routerLink="{{env.frontPath}}about-us" class="btn custom1-btn marginTop marginBottomNone">Read more</a>

</div>

<div class="col-sm-1Third paddingInBoxExtra tabpaddingInBox">
<div class="row titleFont font20 brown marginBottom">Main Links</div>
<div class="row">
<!-- <a routerLink="/" class="links"><i class="fa fa-home"></i> Home</a> -->
<a routerLink="{{env.frontPath}}about-us" class="links"><i class="fa fa-info-circle"></i> About Us</a>
<a routerLink="{{env.frontPath}}track-application" class="links"><i class="fa fa-search"></i> Track</a>
<a href="#" class="links"><i class="fa fa-handshake-o"></i> Mice</a>
<!--<a href="#" class="links"><i class="fa fa-handshake-o"></i> Become a Partner</a>-->
<a routerLink="{{env.frontPath}}contact-us" class="links"><i class="fa fa-address-book"></i> Contact Us</a>
<a routerLink="{{env.frontPath}}terms-of-use" class="links"><i class="fa fa-file"></i> Terms of Use</a>
<a routerLink="{{env.frontPath}}privacy-policy" class="links"><i class="fa fa-user"></i> Privacy Policy</a>
<a routerLink="testimonial" class="links"><i class="fa fa-user"></i> Testimonials</a>

<!--<a routerLink="blogs" class="links"><i class="fa fa-sticky-note"></i> Blog</a>-->
<a routerLink="faqs" class="links"><i class="fa fa-info-circle"></i> FAQ</a>
<!--<a routerLink="/video" class="links"><i class="fa fa-info-circle"></i> Videos</a>-->
<!-- <a class="links"><i class="fa fa-binoculars"></i> Tours</a>
<a class="links"><i class="fa fa-building"></i> Hotels</a>
<a class="links"><i class="fa fa-plane"></i> Flights</a> -->
</div>
</div>

<div class="col-sm-1Third paddingInBoxExtra tabpaddingInBox mobfont12">
<div class="row titleFont font20 brown marginBottom">Contact Us</div>
<div class="row"><i class="fa fa-whatsapp marginRight"></i> <a href="tel:+971505863986" class="grey floatNone">+971-505863986</a></div>
<div class="row grey"><i class="fa fa-phone marginRight"></i> <a href="tel:+97145464650" class="grey floatNone">+971-45464650</a> (10 AM - 7 PM Dubai Time)</div> 
<div class="row marginBottom paddingBottom"><i class="fa fa-envelope marginRight"></i> <a href="mailto:contact@instaglobaltravel.com" class="grey floatNone">contact@instaglobaltravel.com</a></div>

<div class="row titleFont font20 brown marginBottom">Our Location</div>
<div class="row"><strong>INSTA TOURISM LLC</strong></div>
<div class="row marginBottom">201, M Square Commercial Building, Near Double Tree Hotel, Bur Dubai, Dubai, UAE</div>

<div class="row">
<img src="{{env.baseUrl}}/assets/images/payment.png" class="pull-left" alt="InstaGobalTravel" title="Card Accepted" width="150" height="33">
</div>


</div>

</div>

<div class="row greyBG borderTop">
<div class="wrap black paddingupdwn tabpaddingInBox">
<div class="col-sm-5 mobRow mobfont11 mobAlignCenter">
<div class="soicalLinks margin-top">
    <a href="https://www.facebook.com/Instaglobaltourism-104059668840471" class="facebook marginLeftNone" title="Facebook" target="_blank"><i class="fa fa-facebook"></i></a>
    <a href="https://twitter.com/InstaglobalT" class="twitter" title="Twitter" target="_blank"><i class="fa fa-twitter"></i></a>
    <a href="https://www.linkedin.com/company/78122749/admin/" title="LinkedIn" class="linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
    <a href="https://www.youtube.com/channel/UCnc7SaMeOJ3XN9S_SIPrUrQ" title="Youtube" class="youtube" target="_blank"><i class="fa fa-youtube"></i></a>
    <a href="https://www.instagram.com/instaglobaltourism/" class="instagram" title="Insatgram" target="_blank"><i class="fa fa-instagram"></i></a>
    </div>
    <span class="marginLeft paddingLeft borderLeft">Copyright © 2022. All rights reserved by Instaglobaltourism.com</span> 
</div>
<div class="col-sm-5 mobRow">
<!-- <div class="pull-right soicalLinks">
<a href="https://www.facebook.com/Instaglobaltravel-102193425411327" class="facebook" target="_blank"><i class="fa fa-facebook"></i></a>
<a href="https://twitter.com/global__travel" class="twitter" target="_blank"><i class="fa fa-twitter"></i></a>
<a href="https://www.linkedin.com/company/instaglobaltravel" class="linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
<a href="https://www.youtube.com/channel/UCW7djHkWjjBr5AopYGICAgg" class="youtube" target="_blank"><i class="fa fa-youtube"></i></a>
<a href="https://www.instagram.com/insta_global_travel/" class="instagram" target="_blank"><i class="fa fa-instagram"></i></a>
</div> -->
</div>
</div>
</div>
</footer>
<!-- footer Panel -->

