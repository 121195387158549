import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsPage {

  constructor( 
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute) { }

    getCmspage(page_slug:any) {       
        return this.http.post<[any]>(`${environment.apiUrl}/Cmstourism/getPage`, {page_slug})
        .pipe(map(pagedata => {	           			
            return pagedata;
        }));
    }	

    getCmspageSearch(page_slug:any,destination:any,nationality:any,livingin:any) {       
        return this.http.post<[any]>(`${environment.apiUrl}/Cmstourism/getPage`, {page_slug,destination,nationality,livingin})
        .pipe(map(pagedata => {	           			
            return pagedata;
        }));
    }	

    getSeoPage(page_slug:any) {       
        return this.http.post<[any]>(`${environment.apiUrl}/Cmstourism/getSeoUrl`, {page_slug})
        .pipe(map(pagedata => {	           			
            return pagedata;
        }));
    }

    getSeoPageById(soeId:any) {       
        return this.http.post<[any]>(`${environment.apiUrl}/Cmstourism/getSeoPageById`, {soeId})
        .pipe(map(pagedata => {	           			
            return pagedata;
        }));
    }


	websiteVisitor() {
		
		let pathUrl=window.location.href;
		let refUrl=document.referrer;	
		//let sessionId='29834729834789234';
		let sessionId = sessionStorage.getItem("sessionId");
		
		if(sessionStorage.getItem("sessionId")==null){
			let t = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
			sessionStorage.setItem("sessionId",t.toString());	
		}
		
		let getSessionId=sessionStorage.getItem("sessionId");
		sessionStorage.setItem("pathUrl",window.location.href);
		
			return this.http.post<[any]>(`${environment.apiUrl}/cms/webSiteVisiter`, {refUrl,pathUrl,getSessionId})
			.pipe(map(pagedata => {	           			
				return pagedata;
			}));
		}	
      
}