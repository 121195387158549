export const environment = {
  production: true,
  apiUrl : 'https://api.instaglobaltravel.com',
  baseUrl : 'https://www.instaglobaltourism.com',
  cdnUrl : 'https://www.instaglobaltourism.com',
  pgUrl : 'http://www.instaglobaltourism.com',
  frontPath : 'online/',
  customerPath : 'customer/',
  visaPath : 'visa/',
  domain_id: '3',
  lang: 'en',
  razorpay:{
    store_id:'DIpdp4Mf7YGj2V',
    key_id:'rzp_test_z9vU9kj0HYWbzC',
    secret_key_id:'jG4vgIKZonaZUVpz0IwcNXbZ',
    pay_url:''
  },
  
payPalApiKey:'AbzG9FyfIx3XbjalVuIKAKW7YqbKIalH55m51qcpybCtkbfMkJaxa9pBTkx_HPJM2-y0Zyo63Zg1-Rd4',
stripepublishableKeygoogle:'pk_live_51ItWEsJsCmptQYr58Yjism55MzFYgTkvSBkvJ67COwpdr0BNLf4E8Xj8LYcuPHrqtNaO8J0SQRW1C68Gkvs8vztF009aSbhG9I',
gpaymerchantId:'BCR2DN6TU7IMJ2DC',
merchantName:'Insta Tourism LLC',
environmentgpay: 'PRODUCTION'
};
