import { Component, OnInit } from '@angular/core';
import { environment } from  '../../../environments/environment';
declare let $: any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	env = environment; 
  constructor() { }

ngOnInit(): void {

 
  }

}
