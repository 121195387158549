import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagenotfoundComponent} from './modules/front/pagenotfound/pagenotfound.component';


const routes: Routes = [
  { 
    path: 'online', 
    loadChildren: () => import('./modules/front/front.module').then(m => m.FrontModule) ,
    data:{title:'Online'}
  },
  { 
    path: 'blogs', 
    loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule) ,
    data:{title:'Blogs'}
  },
  { 
    path: 'faqs', 
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule) ,
    data:{title:'Faq'}
  },
  { 
    path: 'testimonial', 
    loadChildren: () => import('./modules/testimonial/testimonial.module').then(m => m.TestimonialModule) ,
    data:{title:'Faq'}
  },
  { 
    path: 'cities', 
    loadChildren: () => import('./modules/traveladvisor/cities.module').then(m => m.CitiesModule),
    data:{title:'Cities'}
  },
  { 
    path: 'city/:city_id', 
    loadChildren: () => import('./modules/traveladvisor/city.module').then(m => m.CityModule),
    data:{title:'City'}
  },
  { 
    path: 'place/:place_id', 
    loadChildren: () => import('./modules/traveladvisor/place.module').then(m => m.placeModule),
    data:{title:'Place'} 
  },
  { 
    path: 'place/country/:country_id', 
    loadChildren: () => import('./modules/traveladvisor/countries.module').then(m => m.countriesModule),
    data:{title:'Country'}
  },
  { 
    path: '', 
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    data:{title:'Home'} 
  },
  { 
    path: 'customer', 
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    data:{title:'Customer'}
  },
  { 
    path: 'dubai-visa-application-form', 
    loadChildren: () => import('./modules/landingform/landingform.module').then(m => m.LandingFormModule),
    data:{title:'Visa Application Form'} 
  },
  { 
    path: 'visa', 
    loadChildren: () => import('./modules/visa/visa.module').then(m => m.VisaModule),
    data:{title:'Visa Application Form'} 
  },
  { 
    path: 'travel-visa', 
    loadChildren: () => import('./modules/response/response.module').then(m => m.ResponseModule),
    data:{title:'Travel Visa'} 
  },
  { 
    path: 'apply-visa', 
    loadChildren: () => import('./modules/applyvisa/applyvisa.module').then(m => m.ApplyvisaModule),
    data:{title:'Apply Visa'}  
  },
  { 
    path: ':id/:destination/:visatypeid', 
    loadChildren: () => import('./modules/getvisa/getvisa.module').then(m => m.GetvisaModule),
    data:{title:'Get Visa'}
  },
  { 
    path: 'visa-online/:onlineurl', 
    loadChildren: () => import('./modules/howtoapply/howtoapply.module').then(m => m.HowtoapplyModule),
    data:{title:'Visa Online'}
  },
  { 
    path: 'eligibility/:onlineurl', 
    loadChildren: () => import('./modules/eligibility/eligibility.module').then(m => m.HowtoapplyModule),
    data:{title:'Visa Eligibility'} 
  },
  { 
    path: 'requirement/:onlineurl', 
    loadChildren: () => import('./modules/requirement/requirement.module').then(m => m.RequirementModule),
    data:{title:'Visa Requirement'} 
  },
  { 
    path: 'visa-type/:onlineurl', 
    loadChildren: () => import('./modules/visatypes/visatype.module').then(m => m.VisatypeModule),
    data:{title:'Visa Types'}  
  },
  { 
    path: 'eligible-country/:onlineurl', 
    loadChildren: () => import('./modules/eligiblecountries/eligiblecountries.module').then(m => m.EligiblecountriesModule),
    data:{title:'Eligible Countries'}
  },
  { 
    path: 'embassies/:onlineurl', 
    loadChildren: () => import('./modules/embassies/embassies.module').then(m => m.EmbassiessModule),
    data:{title:'Embassies'} 
  },
  { 
    path: 'fees/:onlineurl', 
    loadChildren: () => import('./modules/destinationvisafee/visafee.module').then(m => m.VisafeeModule),
    data:{title:'Visa Fees'}  
  },
  { 
    path: 'track/:onlineurl', 
    loadChildren: () => import('./modules/track/track.module').then(m => m.TrackModule),
    data:{title:'Track Visa Status'} 
  },
  { 
    path: 'service/:onlineurl', 
    loadChildren: () => import('./modules/applyservice/applyservice.module').then(m => m.ApplyserviceModule),
    data:{title:'Service'}
  },
  { 
    path: 'service-online/:onlineurl', 
    loadChildren: () => import('./modules/applyserviceonline/applyserviceonline.module').then(m => m.ApplyserviceonlineModule),
    data:{title:'Service Online'}
  },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
