import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { environment } from  '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

declare let $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  sessionUserId ='';
  sessionFirstName ='';
  sessionLastName ='';
  cdnUrl:any;
  saveRefData:any;
  env = environment;
  
  
  constructor(
	  private http: HttpClient,
      private router : Router, 
      private route: ActivatedRoute
    ){ }

  ngOnInit() {
	  
    this.cdnUrl=environment.cdnUrl;
     if(localStorage.getItem('currentUser')){
      this.isLoggedIn =true;
    }
	
	$(".menuIcon").click(function(){
		$(".navigation-panel").slideToggle();
	});
	
	//this.referrer();
  }
  
  
  logout() {
    localStorage.removeItem('currentUser');
    this.router.navigate(['/customer/login']).then(() => {
      window.location.reload();
    });    
  }
  
  referrer(){
	  /* var pathurl=window.location.href;
	  
		  
	  this.http.post<any>(`${environment.apiUrl}/cms/webSiteVisiter`, {pathurl}).subscribe(res => {
		return res;
	  }); */
	  
	  
	  	  
	
   /*  this.saveRefData = {
		referrer: document.referrer
    };
   
    this.visaService
      .saveAppliction(this.saveRefData)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data['Code'] == '200') {
            this.toastr.success('Success', 'Personal details modified successfully');
            //console.log(data['Data'].Count_data);
            if (data['Data'].Count_data == 1) {
              this.router.navigate(['/order-summary/' + localStorage.getItem('refNo')], { relativeTo: this.route });
            } else {
              setTimeout(() => {
                window.location.reload();
              }, 100);
            }
          } else {
          }
        },
        (error) => { }
      );
	console.log(initreferrer); */
  }
  

}
