import { Component,OnInit, Input, Output} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
//import { HelperService } from './service/helper.service';

@Component({
  selector: 'body',
  template:'<app-header></app-header><app-loader></app-loader><router-outlet></router-outlet><app-footer></app-footer>'
  // templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css'],
  //providers:[HelperService]
})
export class AppComponent {
  amount: number = 500;
  title = 'globaltravel';
  loading = false;
  constructor(private router: Router){} 
 ngOnInit() {
   this.router.events.subscribe((evt) => {
     if (!(evt instanceof NavigationEnd)) {
       return;
     }
     window.scrollTo(0, 0);
   });
 }
 deposit(){
  this.amount +=100;
}
}
